import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
} from '@dougs/ds';
import { EcommerceShopUrlComponentService } from '../../../../../../services/ecommerce-shop-url.component.service';

@Component({
  selector: 'dougs-ecommerce-shop-url',
  standalone: true,
  imports: [
    CommonModule,
    ControlFormFieldDirective,
    ErrorFormFieldDirective,
    FormFieldComponent,
    LabelFormFieldDirective,
    ReactiveFormsModule,
  ],
  providers: [
    EcommerceShopUrlComponentService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EcommerceShopUrlComponent),
      multi: true,
    },
  ],
  templateUrl: './ecommerce-shop-url.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcommerceShopUrlComponent implements ControlValueAccessor {
  @Input() set isTouched(isTouched: boolean) {
    this.ecommerceShopUrlComponentService.isTouched = isTouched;
  }

  @Input({ required: true }) set shopType(shopType: string | null) {
    this.ecommerceShopUrlComponentService.shopType = shopType;
  }

  @Input() noMargin = false;

  emitOnValueChanges$: Observable<void> = this.ecommerceShopUrlComponentService.control.valueChanges.pipe(
    map((value) => this.onChange(value)),
  );

  constructor(public readonly ecommerceShopUrlComponentService: EcommerceShopUrlComponentService) {}

  onChange!: (value: string | null) => void;
  onTouched!: () => void;

  writeValue(value: string): void {
    this.ecommerceShopUrlComponentService.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.ecommerceShopUrlComponentService.control.disable()
      : this.ecommerceShopUrlComponentService.control.enable();
  }
}
